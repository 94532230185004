import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { EmptySerializer } from '../serializers';
import { BaseApi } from './base.api';
import { BaseService } from './base.service';
import { Customer, CustomerCompleteRegisterEvent, CustomerProfileChangeEvent, CustomerRegisterEvent } from '../models';

@Injectable({
    providedIn: 'root'
})
export class CustomerAuthService {
    private api: BaseApi;

    public constructor(
        private base: BaseService,
        private storage: StorageService
    ) {
    }

    public loadBaseApi(): void {
        this.api = this.base.setApi('gateway', 'customer');///CustomerAuth
    }

    public registerNewCustomer(event: CustomerRegisterEvent): Observable<Customer> {
        const data = {
            ...event
        };

        return this.api.post('Register', data, new EmptySerializer());
    }

    public completeRegistration(event: CustomerCompleteRegisterEvent): Observable<Customer> {
        return this.api.post('CompleteRegistration', event, new EmptySerializer());
    }

    public confirmCustomer(email: string, validationCode: string): Observable<Customer> {
        const data = {
            validationCode: validationCode,
            email: email
        };

        return this.api.post('ConfirmCustomer', data, new EmptySerializer());
    }

    public login(email: string, pass: string, origin: number): Observable<Customer> {
        const data = {
            email: email,
            password: pass,
            origin: origin
        };
    
        return this.api.post<Customer>('Login', data);
    }    

    public requestPasswordRecovery(email: string, baseUrl: string): Observable<any> {
        const data = {
            email: email,
            baseUrl: baseUrl,
            origin: this.base.configService.company.origin
        };

        return this.api.post('PasswordRecovery', data, new EmptySerializer());
    }

    public requestPasswordChange(validationCode: string, password: string): Observable<any> {
        const data = {
            validationCode: validationCode,
            password: password
        };

        return this.api.post('PasswordChange', data, new EmptySerializer());
    }

    public changeProfile(event: CustomerProfileChangeEvent): Observable<any> {
        const data = {
            ...event
        };

        return this.api.post('ChangeProfile', data, new EmptySerializer());
    }

    public isAuthenticated(): boolean {
        return this.storage.getLoggedCustomer() !== null;
    }

    public sendConfirmationCode(email: string): Observable<any> {
        const data = {
            email: email,
            origin: this.base.configService.company.origin
        };

        return this.api.post('SendConfirmationCode', data, new EmptySerializer());
    }
}
